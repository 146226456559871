import {useState} from "react"
import {ErrorMessage, Field, Form, Formik} from "formik"
import * as Yup from "yup";
import {useMutation} from "@apollo/client"
import ResetPasswordMutation from "modules/auth/scenes/ResetPassword/mutations/ResetPasswordMutation"

export default function ResetPassword({match, history}) {
  const [graphQLErrors, setGraphQLErrors] = useState(null)
  const [resetPassword, {loading, data}] = useMutation(ResetPasswordMutation, {
    onCompleted(data) {
      if (data.resetPassword.passwordChanged === true)
        history.push("/auth/reset-password/success")
    },
    onError(error) {
      setGraphQLErrors(error.graphQLErrors)
    }
  })

  const {token, uid} = match.params;

  const initialValues = {
    password: "",
    rePassword: ""
  }
  const validationSchema = Yup.object({
    password: Yup.string()
        .required("Éste campo es obligatorio"),
    rePassword: Yup.string()
        .required("Éste campo es obligatorio")
  })

  function onSubmit(values) {
    resetPassword({
      variables: {
        input: {
          uid,
          token,
          newPassword: values.password
        }
      }
    })
  }

  return (
      <div className="container">
        <h1>Cambia tu contraseña</h1>
        <p>Diseño de ésta página pendiente...</p>
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}>
          {
            ({errors, isSubmitting}) => (
                <Form noValidate>
                  <div className="form-group">
                    <label htmlFor="password">
                      Contraseña
                    </label>
                    <Field
                        id="password"
                        name="password"
                        type="password"
                        className="form-control"/>
                    <ErrorMessage name="password"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="rePassword">
                      Confirmar contraseña
                    </label>
                    <Field
                        id="rePassword"
                        name="rePassword"
                        type="password"
                        className="form-control"/>
                    <ErrorMessage name="rePassword"/>
                  </div>
                  {
                    (
                        data &&
                        data.resetPassword.validationErrors &&
                        data.resetPassword.validationErrors.length > 0
                    ) &&
                    <div>
                      {
                        data.resetPassword.validationErrors.map((error, index) =>
                            <div key={index}>{error}</div>
                        )
                      }
                    </div>
                  }
                  {
                    graphQLErrors &&
                    graphQLErrors.map((error, index) =>
                        <div key={index}>{error.message}</div>
                    )
                  }
                  <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading}>
                    {loading ? "Cambiando..." : "Cambiar"}
                  </button>
                </Form>
            )
          }
        </Formik>
      </div>
  )
}