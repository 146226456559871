export default function ResetPwdSuccess() {
  return (
      <div className="container">
        <h1>Contraseña cambiada!</h1>
        <p>
          Vuelve a la APP en tu celular y prueba ingresar con tu nueva contraseña.
        </p>
        <p>
          Puedes cerrar ésta página.
        </p>
      </div>
  )
}