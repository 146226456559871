import {gql} from "@apollo/client"

const ResetPasswordMutation = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      passwordChanged
      validationErrors
    }
  }
`

export default ResetPasswordMutation