import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import {BrowserRouter, Route} from "react-router-dom"
import ResetPassword from "modules/auth/scenes/ResetPassword"
import "./assets/styles/RumbaMoneyWeb.scss"
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client"
import ResetPwdSuccess from "modules/auth/scenes/ResetPwdSuccess"

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache()
});

ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Route path="/auth/reset-password/:uid/:token" component={ResetPassword}/>
          <Route exact path="/auth/reset-password/success" component={ResetPwdSuccess}/>
        </BrowserRouter>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
